import { mapActions, mapGetters } from "vuex";
import { minLength, required, sameAs } from "vuelidate/lib/validators";
import validation from "@/mixins/validation";

export default {
    name: "user-change-password",
    data() {
        return {
            payload: {
                oldPassword: "",
                newPassword: "",
                confirmPassword: "",
            },
            oldPassErrors: "",
            isEdit: false,
            validationErrors: {},
        };
    },
    mixins: [validation],
    validations: {
        payload: {
            oldPassword: {
                required,
                minLength: minLength(8),
            },
            newPassword: {
                required,
                minLength: minLength(8),
                sameAsPassword: sameAs("confirmPassword"),
            },
            confirmPassword: {
                required,
                minLength: minLength(8),
                sameAsPassword: sameAs("newPassword"),
            },
        },
    },

    computed: {
        ...mapGetters({
            passwordLoading: "auth/passwordLoading",
        }),
        oldPasswordErrors() {
            return this.computeErrors('payload', 'oldPassword', {
                required: "validationRequired",
                minLength: {message: "validationMin", count: 8},
            });
        },
        newPasswordErrors() {
            return this.computeErrors('payload', 'newPassword', {
                required: "validationRequired",
                minLength: {message: "validationMin", count: 8},
                sameAsPassword: "validationSameAs",
            });
        },
        confirmPasswordErrors() {
            return this.computeErrors('payload', 'confirmPassword', {
                required: "validationRequired",
                sameAsPassword: "validationSameAs",
                minLength: {message: "validationMin", count: 8},
            });
        },
    },
    watch: {},
    created() {},
    methods: {
        ...mapActions({
            changePassword: "auth/CHANGE_PASSWORD",
        }),
        resetForm() {
            this.isEdit = false;
            this.$v.$reset();
        },
        update() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                let obj = {};
                obj.password_current = this.payload.oldPassword;
                obj.password = this.payload.newPassword;
                obj.password_confirmation = this.payload.confirmPassword;
                this.changePassword(obj)
                    .then(() => {
                        this.$toasted.success(this.$t("passwordSuccessfullyChanged"));
                        this.isEdit = false;
                    })
                    .catch(error => {
                        if (!error.data.errors.passwordCurrent) {
                            return;
                        }

                        this.oldPassErrors = error.data.errors.passwordCurrent.join("");
                    });
            }
        },
    },
};
